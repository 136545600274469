import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import FAQGroup from "../components/festival/faq-group"
import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import FestivalHeader from "../components/festival/festival-header"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import SEO from "../components/seo/seo"
import PartnerGroup from "../components/festival/partner-group"

class FAQTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()
    }

    animate(){
      this.pageTransition.current.play()
    }

    render(){

        const { location, seo } = this.props.data
        const locale = this.props.pageContext.locale
        return (
          <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
            <SEO
              title={seo.title}
              description={seo.description}
              keywords={seo.keywords && seo.keywords.keywords}
              image={seo.image.file.url}
            />
            <FestivalNavigation locale={locale} links={location.navigation.links} color={'#F5867B'} />
            <FestivalHeader wide={location.theme.widescreenHeaderImage.file.url} portrait={location.theme.portraitHeaderImage.file.url} animation={location.theme.animation.file.url}>
              <div className={"container mx-auto py-20 sm:py-4 md:py-0 lg:py-20 text-center leading-none"}>
                <p className={"font-formula-bold text-lg lg:text-2xl text-white uppercase tracking-wider"}>{`the drop festival ${location.title}`}</p>
                <p className={"font-montserrat-extra-bold text-3xl md:text-7xl lg:text-9xl text-white uppercase"}>FAQ's</p>
              </div>
            </FestivalHeader>
            <FestivalBackground wide={location.theme.widescreenBackground.file.url}>
                <div className={"container mx-auto"}>
                  <div className={"flex flex-wrap"}>
                    <div className={"w-full md:w-2/3 py-12 mx-auto px-4 md:px-0"}>
                    {
                      location.faqs.map((faq, index) => {
                        return <FAQGroup title={faq.title} faqs={faq.questionAnswers} key={`faq-${index}`} locale={locale} />
                      })
                    }
                    </div>
                    {/* <div className={"w-full md:w-1/3 py-12"}>
                      <div className={"pl-12"}>
                        <div style={{borderBottom:"4px", borderBottomColor:"#9cf2f7", borderBottomStyle:'solid'}}>
                          <h1 className={"font-agrandir text-3xl mb-4"}>Quick links</h1>
                        </div>
                        <nav className={"list-none pt-4"}> 
                        {
                          location.faqs.map((faq, index) => {
                            return <li className={"font-formula-bold text-lg py-1 uppercase"}>{faq.title}</li>
                          })
                        }
                        </nav>
                      </div>
                    </div> */}
                  </div>
                </div>
                <PartnerGroup partners={location.partners} locale={locale} />
            </FestivalBackground>
            <TransitionPortal>
              <PageTransition ref={this.pageTransition} />
            </TransitionPortal>
          </Layout>
        )
    }
}

export const pageQuery = graphql`
query FAQPageQuery($slug: String!, $locale: String!) {
    seo: contentfulSeo(slug: {eq: "faqs"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    location: contentfulLocation(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      slug
      date
      venue
      faqs{
        ... on ContentfulQuestionAnswerGroup{
          title
          questionAnswers{
            ...on ContentfulQuestionAnswer{
              question
              answer{
                json
              }
            }
          }
        }
      }
      partners {
        ... on ContentfulPartner {
          slug
          logo {
            file {
              url
            }
          }
        }
      }
      theme {
        ... on ContentfulTheme {
          logo {
            file {
              url
            }
          }
          animation{
            file{
              url
            }
          }
          portraitBackground {
            file {
              url
            }
          }
          portraitHeaderImage {
            file {
              url
            }
          }
          widescreenBackground {
            file {
              url
            }
          }
          widescreenHeaderImage {
            file {
              url
            }
          }
          colorPrimary
          colorSecondary
        }
      }
      navigation{
        ...on ContentfulNavigation{
          links{
            ...on ContentfulLink{
              title
              href
            }
          }
        }
      }
    }
}
`

export default FAQTemplate
