import React from "react"
import { TweenMax, Sine, gsap, CSSPlugin } from "gsap"

import PlusIcon from "../../images/plus-icon-gravel.svg"
import MinusIcon from "../../images/minus-icon-gravel.svg"
import RichTextField from "../textfield/rich"

gsap.registerPlugin(CSSPlugin)

class FAQTile extends React.Component {

    constructor(props){

        super(props)
        
        this.state = {
            open: false
        }

        this.draw = null
    }

    toggle = () => {

        if (this.state.open) {
            TweenMax.to(this.draw, 0.1, { css:{height: "0"}, ease: Sine.easeOut })
            this.setState({ open: false })
        } else {
            TweenMax.to(this.draw, 0.1, { css:{height: "auto"}, ease: Sine.easeOut })
            this.setState({ open: true })
        }

    }

    render(){
        return(
            <div className={"relative mt-8 mb-4 pb-4 pr-12"} style={{borderBottom:"1px", borderBottomColor:"#9cf2f7", borderBottomStyle:'solid'}}>
                <div className={"absolute cursor-pointer hover:opacity-50"} style={{top:"0", right:"0"}}>
                    { !this.state.open && <img src={PlusIcon} alt="" onClick={this.toggle} />}
                    { this.state.open && <img src={MinusIcon} alt="" onClick={this.toggle} />}
                </div>
                <span className={"font-agrandir text-lg mb-4 pr-4"}>{this.props.question}</span>
                <div className={"overflow-hidden pr-8 py-2"} style={{height:'0px'}} ref={ div => (this.draw = div)}>
                    <RichTextField json={this.props.answer.json} locale={this.props.locale} />
                </div>
            </div>
        )
    }

}

export default FAQTile