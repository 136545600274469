import React from "react"

import RedWiglyLine from "../../images/wigly-red-horizontal.svg"
import FAQTile from "./faq-tile"

const FAQGroup = ({title, faqs, locale}) => {
    return(
        <div className={"pb-8"}>
            <h1 className={"font-agrandir text-3xl mb-4"}>{title}</h1>
            <div className={"w-full py-2"} style={{backgroundImage:`url(${RedWiglyLine})`, backgroundRepeat:'repeat-x'}} />
            {
                faqs.map((faq, index) => {
                    return (<FAQTile question={faq.question} locale={locale} answer={faq.answer} key={`faq-${index}`} />)
                })
            }
        </div>
    )
}

export default FAQGroup